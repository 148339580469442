import { FC, useEffect, useRef, useState } from 'react'
import { styled } from '@magal/styles'
import { SectionCollectionsSliderProps } from '@magal/models'
import {
  Button,
  Link,
  ProductCard,
  SectionWrap,
  ArrowInCircleButton,
} from '@magal/components'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { GTMViewItemList } from '@magal/services/gtm-service'
import { useProductsSettings } from '@magal/product'
import { ROUTE_BASE_COLLECTIONS } from '@magal/configs'

const Root = styled('div', {
  color: '$green_09',
  display: 'grid',
  '@lg': {
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: '$40',
    marginLeft: '$containerMarginDesktop',
  },
})

const Sidebar = styled('div', {
  position: 'relative',
  display: 'grid',
  variants: {
    asSliderOnMobile: {
      true: {
        gridAutoFlow: 'column',
        overflowX: 'scroll',
        scrollSnapType: 'x mandatory',
        paddingTop: '$20',
        paddingBottom: '$20',
        width: '100vw',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
        '@lg': {
          width: 'auto',
          paddingTop: '$2',
          paddingBottom: '$0',
          display: 'grid',
          gridAutoRows: 'min-content',
          gridAutoFlow: 'inherit',
        },
      },
      false: {
        padding: '$24 $containerMarginMobile',
        '@lg': {
          padding: '$0',
        },
      },
    },
  },
})

const Tab = styled('div', {
  scrollSnapAlign: 'center',
  display: 'block',
  alignItems: 'start',
  justifyContent: 'center',
  textAlign: 'center',
  columnGap: '$16',
  paddingLeft: '10vw',
  paddingRight: '10vw',
  paddingTop: '$4',
  paddingBottom: '$4',
  '@lg': {
    gridColumn: 'span 3',
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    justifyContent: 'left',
    alignItems: 'center',
    textAlign: 'left',
    paddingLeft: 0,
    paddingRight: 0,
  },
})

const SliderHeading = styled('div', {
  display: 'block',
})

const SliderTitle = styled('h2', {
  projectFont: 'caps03',
})

const SliderSubtitle = styled('p', {
  projectFont: 'body01',
  marginTop: '$12',
})

const StyledLink = styled(Link, {
  display: 'inline-block',
  textDecoration: 'underline',
  marginTop: '$12',
})

const StyledTabLink = styled(Button, {
  color: '$green_09',
  background: 'none',
  projectFont: 'caps03',
  width: 'max-content',
  padding: '$4 0',
  variants: {
    isActive: {
      true: {
        position: 'relative',
        '&::after': {
          content: '',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '1px',
          bottom: 0,
          left: 0,
          backgroundColor: '$green_09',
        },
      },
    },
  },
})

const StyledSeeAllLink = styled(Link, {
  projectFont: 'caps06',
  position: 'relative',
  marginTop: '$12',
  '@lg': {
    marginTop: 0,
  },
  variants: {
    isActive: {
      true: {
        display: 'inline-block',
      },
      false: {
        display: 'none',
      },
    },
  },
})

const NavButtons = styled('div', {
  display: 'none',
  '@lg': {
    position: 'absolute',
    placeSelf: 'center end',
    display: 'grid',
    gap: '$4',
    gridTemplateColumns: 'auto auto',
  },
})

const Sliders = styled('div', {
  position: 'relative',
  maxWidth: '100vw',
  display: 'grid',
  '@md': {
    gridColumn: 'span 3',
  },
})

const ProductSlider = styled('div', {
  $$gapValue: '$sizes$6',
  width: '100%',
  gridAutoFlow: 'column',
  gridAutoColumns: '75vw',
  gap: '$$gapValue',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  '@md': {
    gridAutoColumns: '40vw',
  },
  '@lg': {
    gridAutoColumns: 'calc(33.333% - $$gapValue)',
  },
  display: 'grid',
  gridRow: 1,
  gridColumn: 1,
  transition: 'opacity 200ms',
  variants: {
    isActive: {
      true: {
        zIndex: 1,
        opacity: 1,
      },
      false: {
        zIndex: -1,
        opacity: 0,
      },
    },
  },
})

const ProductCardWrapper = styled('div', {
  display: 'grid',
  scrollSnapAlign: 'center',
  '> *': {
    gridArea: '1/1',
  },
  width: '100%',
  '@lg': {
    scrollSnapAlign: 'start',
  },
})

const FirstSliderIndicator = styled('div', {
  placeSelf: 'center start',
  height: '$96',
  width: '$4',
})

const LastSliderIndicator = styled('div', {
  placeSelf: 'center end',
  height: '$96',
  width: '$4',
})

export const SectionCollectionsSlider: FC<SectionCollectionsSliderProps> = ({
  collectionsIds,
  shopifyData,
}) => {
  const [activeSliderIndex, setActiveSliderIndex] = useState(0)
  const [prevSlideButtonIsActive, setPrevSlideButtonIsActive] = useState(true)
  const [nextSlideButtonIsActive, setNextSlideButtonIsActive] = useState(true)
  const sidebarRef = useRef<HTMLDivElement | null>(null)
  const itemsRef = useRef<Array<HTMLDivElement | null>>([])
  const firstCardsRef = useRef<Array<HTMLDivElement | null>>([])
  const lastCardsRef = useRef<Array<HTMLDivElement | null>>([])
  const { t } = useTranslation('sectionCollectionSlider')
  const [rootRef, sectionInView] = useInView({
    triggerOnce: true,
  })

  const { updateProductColorsMap } = useProductsSettings()

  const getCurrentSlideNumber = () => {
    const scrollLeft = itemsRef.current[activeSliderIndex]?.scrollLeft
    const clientWidth =
      itemsRef.current[activeSliderIndex]?.children[1].clientWidth

    if (!scrollLeft || !clientWidth || scrollLeft === 0 || clientWidth === 0) {
      return 0
    }

    return Math.round(scrollLeft / clientWidth)
  }

  const scrollBySlides = (numberOfSlides: number) => {
    if (itemsRef.current[activeSliderIndex]) {
      const currentSlideWidth =
        itemsRef.current[activeSliderIndex]?.children[1].clientWidth
      itemsRef.current[activeSliderIndex]?.scrollBy({
        left: currentSlideWidth ? numberOfSlides * currentSlideWidth : 0,
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const scrollTo = (slideNumber: number) => {
    if (itemsRef.current && itemsRef.current[activeSliderIndex]) {
      const currentIndex = getCurrentSlideNumber()
      scrollBySlides(slideNumber - currentIndex)
    }
  }

  const prev = () => {
    scrollTo(getCurrentSlideNumber() - 1)
  }

  const next = () => {
    scrollTo(getCurrentSlideNumber() + 1)
  }

  const changeActiveSliderIndex = (index: number) => {
    setActiveSliderIndex(index)

    if (sidebarRef.current) {
      const scrollLeftCurrentPosition =
        sidebarRef.current.children[index].getBoundingClientRect()

      const scrollDistance =
        -1 *
        (window.innerWidth / 2 -
          (scrollLeftCurrentPosition.x + scrollLeftCurrentPosition.width / 2))

      if (Math.abs(scrollDistance) > 10) {
        sidebarRef.current.scrollBy({
          left: scrollDistance,
          top: 0,
          behavior: 'smooth',
        })
      }
    }
  }

  useEffect(() => {
    const firstCardObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setPrevSlideButtonIsActive(false)
          } else {
            setPrevSlideButtonIsActive(true)
          }
        })
      },
      {
        threshold: 0.05,
      },
    )

    const lastCardObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setNextSlideButtonIsActive(false)
          } else {
            setNextSlideButtonIsActive(true)
          }
        })
      },
      {
        threshold: 0.05,
      },
    )

    const activeFirstCard = firstCardsRef.current[activeSliderIndex]
    if (activeFirstCard) firstCardObserver.observe(activeFirstCard)

    const activeLastCard = lastCardsRef.current[activeSliderIndex]
    if (activeLastCard) lastCardObserver.observe(activeLastCard)

    return () => {
      firstCardObserver.disconnect()
      lastCardObserver.disconnect()
    }
  }, [firstCardsRef, lastCardsRef, activeSliderIndex])

  const currentCollection =
    shopifyData?.collectionSliderExcerpt && collectionsIds
      ? shopifyData?.collectionSliderExcerpt[collectionsIds[activeSliderIndex]]
      : undefined

  const currentProducts = currentCollection?.products

  useEffect(() => {
    if (sectionInView && currentCollection) {
      const products = currentCollection?.products

      GTMViewItemList(
        products,
        'section_collections_slider',
        currentCollection.title,
      )
    }
  }, [sectionInView, activeSliderIndex])

  useEffect(() => {
    if (currentProducts && currentProducts.length) {
      updateProductColorsMap(
        Object.values(currentProducts).map((p) => p.fullTitle),
      )
    }
  }, [currentProducts])

  return (
    <SectionWrap>
      <Root ref={rootRef}>
        <Sidebar
          ref={sidebarRef}
          asSliderOnMobile={collectionsIds && collectionsIds.length > 1}
        >
          {collectionsIds &&
            collectionsIds.length > 1 &&
            collectionsIds.map((collectionId, index) => {
              const collection = shopifyData?.collectionSliderExcerpt
                ? shopifyData?.collectionSliderExcerpt[collectionId]
                : undefined

              if (!collection) {
                return null
              }

              return (
                <Tab key={`${collectionId}-tab-${index}`}>
                  <StyledTabLink
                    onClick={() => changeActiveSliderIndex(index)}
                    isActive={activeSliderIndex === index}
                    aria-selected={activeSliderIndex === index}
                    role="tab"
                    id="tab-1"
                    aria-controls={`panel-${index}`}
                  >
                    {collection?.title}
                  </StyledTabLink>
                  <StyledSeeAllLink
                    appearance={'textLinkLite'}
                    href={`${ROUTE_BASE_COLLECTIONS}/${collection?.handle}`}
                    isActive={activeSliderIndex === index}
                  >
                    {t('seeAll')}
                  </StyledSeeAllLink>
                </Tab>
              )
            })}
          {collectionsIds &&
            collectionsIds.length === 1 &&
            collectionsIds.map((collectionId) => {
              const collection = shopifyData?.collectionSliderExcerpt
                ? shopifyData?.collectionSliderExcerpt[collectionId]
                : undefined

              if (!collection) {
                return null
              }

              return (
                <SliderHeading key={`${collection.title}-heading`}>
                  {collection.title && (
                    <SliderTitle>{collection.title}</SliderTitle>
                  )}
                  {collection.description && (
                    <SliderSubtitle>{collection.description}</SliderSubtitle>
                  )}
                  <StyledLink
                    appearance={'textLinkLite'}
                    href={`${ROUTE_BASE_COLLECTIONS}/${collection.handle}`}
                  >
                    {t('discover')}
                  </StyledLink>
                </SliderHeading>
              )
            })}
          <NavButtons>
            <ArrowInCircleButton
              onClick={prev}
              disabled={!prevSlideButtonIsActive}
            />
            <ArrowInCircleButton
              onClick={next}
              disabled={!nextSlideButtonIsActive}
              type={'next'}
            />
          </NavButtons>
        </Sidebar>
        <Sliders>
          {collectionsIds?.map((id, index) => {
            const collection = shopifyData?.collectionSliderExcerpt
              ? shopifyData?.collectionSliderExcerpt[id]
              : undefined

            if (!collection) {
              return null
            }

            const products = collection.products

            return (
              <ProductSlider
                isActive={activeSliderIndex === index}
                aria-labelledby={`tab-${index}`}
                role="tabpanel"
                id={`panel-${index}`}
                key={`${collection.title}-slider-${index}`}
                ref={(el) => (itemsRef.current[index] = el)}
              >
                {products?.map((product, productIndex) => (
                  <ProductCardWrapper
                    key={productIndex}
                    //TODO
                    //aria-hidden="true"
                    //aria-label="slide"
                  >
                    {productIndex === 0 && (
                      <FirstSliderIndicator
                        ref={(el) => {
                          firstCardsRef.current[index] = el
                        }}
                      />
                    )}
                    {productIndex === products.length - 1 && (
                      <LastSliderIndicator
                        ref={(el) => {
                          lastCardsRef.current[index] = el
                        }}
                      />
                    )}
                    <ProductCard
                      key={productIndex}
                      image={product.featuredImage}
                      title={product.title}
                      handle={product.handle}
                      priceRange={product.priceRange}
                      color={product.color}
                      compareAtPriceRange={product.compareAtPriceRange}
                      tags={product.tags}
                    />
                  </ProductCardWrapper>
                ))}
              </ProductSlider>
            )
          })}
        </Sliders>
      </Root>
    </SectionWrap>
  )
}
